import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import MerchantSignUpButton from '../components/merchantSignUpButton'

export default function Pricing () {
  return (
    <Layout type='merchant'>
      <section className='mb-0'>
        <div className='row align-center' style={{ minHeight: 550 }}>
          <div className='col f3'>
            <div className="inner">
              <h1>No monthly, hidden, or start up fees—just honest work.</h1>
              <p className='lede mb'>We charge for the time and distance it takes to deliver from your store to the customer’s doorstep. Your profit margins are yours.</p>
              <MerchantSignUpButton />
            </div>
          </div>
          <div className='col f2'>
            <div className="inner image">
              <img src="/img/pricing-hero.jpg" alt="Person holding bag" />
            </div>
          </div>
        </div>
      </section>

      <section className='well'>
        <div className='row align-center justify-center'>
          <div className='col w75 text-center'>
            <div className="inner" style={{ paddingBottom: 0 }}>
              <h2>Data backed pricing</h2>
              <p>With time and distance pricing, we can’t give you an exact number, but we can provide some scenarios with approximate numbers based on data from thousands of deliveries. The cost of delivery is calculated and shown upfront before any commitment.</p>
            </div>
          </div>
        </div>
        <div className='row align-center justify-center'>
          <div className='col text-center'>
            <div className="inner">
              <h3>Bundled</h3>
              <div className="price-image">
                <img src="/img/pricing-bundled.jpg" alt="A to B, C, D, E, F" />
              </div>
              <p className='mb-0'>Average price range per bundled delivery:</p>
              <span className='price-tag'>$6 - $8</span>
            </div>
          </div>
          <div className='col text-center'>
            <div className="inner">
              <h3>Single</h3>
              <div className="price-image">
                <img src="/img/pricing-single.jpg" alt="A to B" />
              </div>
              <p className='mb-0'>Average price range per single delivery:</p>
              <span className='price-tag'>$13 - $17</span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row reverse equal align-center'>
          <div className='col d-only'>
            <div className="inner image">
              <img src="/img/pricing-box.jpg" alt="box with checklist" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>What you get</h2>
              <p className='mb'>Our entire platform is open to you. Everything you need to start offering affordable local delivery to your current and new customers.</p>
              <ol className='mb'>
                <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                  <i className='material-icons brand-merchant' style={{ marginRight: 10 }}>check_circle</i>
                  Label generation
                </li>
                <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                  <i className='material-icons brand-merchant' style={{ marginRight: 10 }}>check_circle</i>
                  Smart routing
                </li>
                <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                  <i className='material-icons brand-merchant' style={{ marginRight: 10 }}>check_circle</i>
                  Live tracking
                </li>
                <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                  <i className='material-icons brand-merchant' style={{ marginRight: 10 }}>check_circle</i>
                  Email/SMS notifications
                </li>
                <li className='row preserve align-center'>
                  <i className='material-icons brand-merchant' style={{ marginRight: 10 }}>check_circle</i>
                  Live support
                </li>
              </ol>
              <Link to='/merchants/' className='cta-link'>Learn more <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row align-center justify-center'>
          <div className="col w75 text-left d-text-center">
            <div className='inner'>
              <h3>How offering delivery will bring in new customers</h3>
              Having the option of local delivery increases your reach to those who may not have the means to make it to your storefront. It’s like putting a new item on the menu.
            </div>
          </div>
        </div>
      </section>

      <section className='well callout'>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/pricing-talk.jpg" alt="Man talking with someone over the computer" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Talk with a human</h2>
              <p>Book a virtual meeting with us and we’ll happily answer any questions you may have surrounding about our pricing and how we can help grow your business.</p>
              <a href="https://calendly.com/trexitysales/30min" target='_blank' rel='noopener noreferrer' className='cta-link'>Book a meeting <i className="material-icons">keyboard_arrow_right</i></a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col w75 text-center'>
            <div className="inner">
              <h2>We’re in this together</h2>
              <p className='mb'>Your business is our business too. With time and distance pricing, we’re not taking from your bottom line, which means more time and headspace to focus on your business.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
